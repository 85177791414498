import type { Student } from '@revolutionprep/types'

export function useSelfStudy () {
  /**
   * nuxt app
   * ==================================================================
   */
  const { $actor } = useNuxtApp()

  /**
   * route & router
   * ==================================================================
   */
  const route = useRoute()
  const router = useRouter()

  /**
   * stores
   * ==================================================================
   */
  const enrollmentStore = useEnrollmentStore()

  const organizationStore = useOrganizationStore()
  const { organizationInfo: company } = storeToRefs(organizationStore)

  const studyAreaStore = useStudyAreaStore()
  const { studyAreas } = storeToRefs(studyAreaStore)

  /**
   * computed
   * ==================================================================
  */
  // actor
  const actor = computed(() => {
    return $actor.core.actor.value as Student
  })

  const actorId = computed(() => {
    return actor.value?.id || null
  })

  // auth
  const isLoggedIn = computed(() => {
    return Boolean($actor.core.isLoggedIn.value && actor.value)
  })

  // course
  const courseId = computed(() => {
    return Number(route.params.courseId)
  })

  const courseName = computed(() => {
    return selfStudyCourse.value?.subject?.name || ''
  })

  const selfStudyCourses = computed(() => {
    const selfStudyEnrollments = enrollmentStore.getSelfStudyEnrollments()

    return selfStudyEnrollments.map((enrollment) => {
      return enrollment.course
    })
  })

  const selfStudyEnrollmentId = computed(() => {
    const selfStudyEnrollments = enrollmentStore.getSelfStudyEnrollments()

    return selfStudyEnrollments?.find((enrollment) => {
      return enrollment?.course?.id === courseId.value
    })?.id
  })

  const selfStudyCourse = computed(() => {
    return selfStudyCourses.value.find((course) => {
      return course?.id === courseId.value
    })
  })

  const selfStudySubjectId = computed(() => {
    return selfStudyCourse.value?.subject?.id
  })

  const selfStudySubject = computed(() => {
    return selfStudyCourse.value?.subject
  })

  const selfStudyType = computed(() => {
    return selfStudySubject.value?.categoryName === 'Test Prep'
      ? 'test-prep'
      : 'academics'
  })

  // stepper
  const steps = computed(() => {
    return [
      {
        routePath: `/self-study/${
          courseId.value
        }/${
          selfStudyType.value
        }/goals`,
        stepNumber: 1,
        stepTitle: 'Goals'
      },
      {
        routePath: `/self-study/${
          courseId.value
        }/${
          selfStudyType.value
        }/course`,
        stepNumber: 2,
        stepTitle: 'Course'
      }
    ]
  })

  const currentStep = computed(() => {
    const currentRoute = useRoute().fullPath
    return steps.value.find((step) => {
      return step.routePath === currentRoute
    })
  })

  // timezone
  const timezone = computed(() => {
    return new Date().toLocaleString(
      'en',
      { timeZoneName: 'short' }
    ).split(' ').pop()
  })

  /**
  * methods
  * ==================================================================
  */
  function findStudyArea () {
    return studyAreas.value.find((studyArea) => {
      return studyArea.subject?.id === selfStudySubjectId.value
    }) || null
  }

  function toPrevStep (currentStep: number) {
    switch (currentStep) {
      case 1:
        return router.replace({
          path: '/self-study'
        })
      case 2:
        return router.replace({
          path: steps.value[0].routePath
        })
    }
  }

  return {
    actor,
    actorId,
    company,
    courseId,
    courseName,
    currentStep,
    findStudyArea,
    isLoggedIn,
    selfStudyCourse,
    selfStudyCourses,
    selfStudyEnrollmentId,
    selfStudySubject,
    selfStudySubjectId,
    selfStudyType,
    steps,
    timezone,
    toPrevStep
  }
}
